import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import * as Icon from 'feather-icons-react';
import { SERVICE_DETAILS } from '../constants';
import API from '../services/API';
import { useDispatch } from 'react-redux';
import { serviceActions } from '../store/services';

const Sidebar = () => {
  const [menuState, setMenuState] = useState({});
  const location = useLocation();
  const [services, setServices] = useState([]);
  const dispatch = useDispatch();

  const toggleMenuState = (state) => {
    if (menuState[state]) {
      setMenuState({ [state]: false });
    } else if (Object.keys(menuState).length === 0) {
      setMenuState({ [state]: true });
    } else {
      const newState = Object.keys(menuState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      setMenuState({ ...newState, [state]: true });
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      const response = await API.get(SERVICE_DETAILS);
      if (response.status === 200) {
        setServices(response.data);
        dispatch(serviceActions.setService(response.data))
      }
    };

    fetchData();
    const onRouteChanged = () => {
      document.querySelector('#sidebar').classList.remove('active');
      const newState = Object.keys(menuState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      setMenuState(newState);

      const dropdownPaths = [
        { path: '/apps', state: 'appsMenuOpen' },
        { path: '/settings', state: 'setMenuOpen' },
        { path: '/knowledge', state: 'knowledgeElementsMenuOpen' },
        { path: '/checklist', state: 'checklistElementsMenuOpen' },
        { path: '/risk-assessment', state: 'riskElementsMenuOpen' },
        { path: '/document', state: 'documentElementsMenuOpen' },
        { path: '/basic-ui', state: 'basicUiMenuOpen' },
        { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
        { path: '/form-elements', state: 'formElementsMenuOpen' },
        { path: '/tables', state: 'tablesMenuOpen' },
        { path: '/maps', state: 'mapsMenuOpen' },
        { path: '/editors', state: 'editorsMenuOpen' },
        { path: '/icons', state: 'iconsMenuOpen' },
        { path: '/charts', state: 'chartsMenuOpen' },
        { path: '/user-pages', state: 'userPagesMenuOpen' },
        { path: '/error-pages', state: 'errorPagesMenuOpen' },
        { path: '/general-pages', state: 'generalPagesMenuOpen' },
        { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
      ];

      dropdownPaths.forEach((obj) => {
        if (isPathActive(obj.path)) {
          setMenuState((prevState) => ({ ...prevState, [obj.state]: true }));
        }
      });
    };

    onRouteChanged();

    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (document.body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (document.body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, [location]);

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const isPathActiveRisk = (path) => {
    return path.indexOf(location.pathname) !== -1;
  };

  return (
    <nav className="sidebar sidebar-offcanvas sidebar-boxshadow" id="sidebar" onTouchMove={() => document.body.classList.toggle('sidebar-icon-only')}>
      <ul className="nav pt-2">
        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/dashboard">
            <Icon.Home className="menu-icon" />
            <span className="menu-title">Home</span>
          </Link>
        </li>
        <li className={isPathActive('/actions') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/actions">
            <Icon.Target className="menu-icon" />
            <span className="menu-title">Actions</span>
          </Link>
        </li>
        <li className={isPathActive('/apps') ? 'nav-item active' : 'nav-item'}>
          <div className={menuState.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('appsMenuOpen')} data-toggle="collapse">
            <Icon.Layers className="menu-icon" />
            <span className="menu-title">Applications</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={menuState.appsMenuOpen}>
            <ul className="nav flex-column sub-menu">
              {services.length !== 0 &&
                services.map((item) => (
                  <li className="nav-item" key={item.url}>
                    <Link
                      className={isPathActive(item.url) ? 'nav-link active' : 'nav-link'}
                      to={item.url}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}

              {/* <li className="nav-item"> <Link className={isPathActive('/apps/incident') ? 'nav-link active' : 'nav-link'} to="/apps/incident">IR Reporting</Link></li>
              <li className="nav-item"> <Link className={isPathActiveRisk('/apps/asset') ? 'nav-link active' : 'nav-link'} to="/apps/asset">Asset Management</Link></li>
              <li className="nav-item"> <Link className={isPathActive('/apps/risk') ? 'nav-link active' : 'nav-link'} to="/apps/risk">Risk Assessment</Link></li>
              <li className="nav-item"> <Link className={isPathActive('/apps/knowledge') ? 'nav-link active' : 'nav-link'} to="/apps/knowledge">Knowledge</Link></li>
              <li className="nav-item"> <Link className={isPathActive('/apps/document') ? 'nav-link active' : 'nav-link'} to="/apps/document">Documents</Link></li>
              <li className="nav-item"> <Link className={isPathActive('/apps/observation') ? 'nav-link active' : 'nav-link'} to="/apps/observation">Observation</Link></li>
              <li className="nav-item"> <Link className={isPathActive('/apps/eptw') ? 'nav-link active' : 'nav-link'} to="/apps/eptw">ePermit to Work</Link></li> */}
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/logout') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/logout">
            <Icon.LogOut className="menu-icon" />
            <span className="menu-title">Logout</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
