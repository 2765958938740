import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions } from './store/login-slice';
import Spinner from '../app/shared/Spinner';



const Dashboard = lazy(() => import('./pages/Dashboard'));
const Home = lazy(() => import('./home/Dashboard'))

// const Widgets = lazy(() => import('./widgets/Widgets'));

// const KanbanBoard = lazy(() => import('./apps/KanbanBoard'));
// const Chats = lazy(() => import('./apps/Chats'));
// const TodoList = lazy(() => import('./apps/TodoList'));

// const Accordions = lazy(() => import('./basic-ui/Accordions'));
// const Buttons = lazy(() => import('./basic-ui/Buttons'));
// const Badges = lazy(() => import('./basic-ui/Badges'));
// const Breadcrumbs = lazy(() => import('./basic-ui/Breadcrumbs'));
// const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
// const Modals = lazy(() => import('./basic-ui/Modals'));
// const Progress = lazy(() => import('./basic-ui/Progress'));
// const Paginations = lazy(() => import('./basic-ui/Paginations'));
// const TabsPage = lazy(() => import('./basic-ui/TabsPage'));
// const Typography = lazy(() => import('./basic-ui/Typography'));
// const Popups = lazy(() => import('./basic-ui/Popups'));

// const Dragula = lazy(() => import('./advanced-ui/Dragula'));
// const Clipboard = lazy(() => import('./advanced-ui/Clipboards'));
// const ContextMenu = lazy(() => import('./advanced-ui/ContextMenus'));
// const Sliders = lazy(() => import('./advanced-ui/Sliders'));
// const Carousel = lazy(() => import('./advanced-ui/Carousel'));
// const Loaders = lazy(() => import('./advanced-ui/Loaders'));
// const TreeView = lazy(() => import('./advanced-ui/TreeView'));

// const BasicElements = lazy(() => import('./form-elements/BasicElements'));
// const AdvancedElements = lazy(() => import('./form-elements/AdvancedElements'));
// const Wizard = lazy(() => import('./form-elements/Wizard'));
// const DocumentCurate = lazy(() => import('./curation/DocCuration'))
// const BasicTable = lazy(() => import('./tables/BasicTable'));
// const ReactTable = lazy(() => import('./tables/ReactTable'));
// const SortableTable = lazy(() => import('./tables/SortableTable'));

// const VectorMap = lazy(() => import('./maps/VectorMap'));
// const SimpleMap = lazy(() => import('./maps/SimpleMap'));

// const Notifications = lazy(() => import('./notifications/Notifications'));

// const Mdi = lazy(() => import('./icons/Mdi'));
// const FlagIcons = lazy(() => import('./icons/FlagIcons'));
// const FontAwesome = lazy(() => import('./icons/FontAwesome'));
// const SimpleLine = lazy(() => import('./icons/SimpleLine'));
// const Themify = lazy(() => import('./icons/Themify'));
// const TypIcons = lazy(() => import('./icons/TypIcons'));

// const TextEditors = lazy(() => import('./editors/TextEditors'));
// const CodeEditor = lazy(() => import('./editors/CodeEditor'));

// const ChartJs = lazy(() => import('./charts/ChartJs'));
// const C3Charts = lazy(() => import('./charts/C3Charts'));
// const Chartist = lazy(() => import('./charts/Chartist'));
// const GoogleCharts = lazy(() => import('./charts/GoogleCharts'));
// const SparkLineCharts = lazy(() => import('./charts/SparkLineCharts'));
// const GuageChart = lazy(() => import('./charts/GuageChart'));

// const Error404 = lazy(() => import('./error-pages/Error404'));
// const Error500 = lazy(() => import('./error-pages/Error500'));


// const Login2 = lazy(() => import('./user-pages/Login2'));
// const Register1 = lazy(() => import('./user-pages/Register'));
// const Register2 = lazy(() => import('./user-pages/Register2'));
// const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

// const BlankPage = lazy(() => import('./general-pages/BlankPage'));
// const Profile = lazy(() => import('./general-pages/Profile'));
// const Faq = lazy(() => import('./general-pages/Faq'));
// const Faq2 = lazy(() => import('./general-pages/Faq2'));
// const NewsGrid = lazy(() => import('./general-pages/NewsGrid'));
// const Timeline = lazy(() => import('./general-pages/Timeline'));
// const SearchResults = lazy(() => import('./general-pages/SearchResults'));
// const Portfolio = lazy(() => import('./general-pages/Portfolio'));

// const Invoice = lazy(() => import('./ecommerce/Invoice'));
// const Pricing = lazy(() => import('./ecommerce/Pricing'));
// const ProductCatalogue = lazy(() => import('./ecommerce/ProductCatalogue'));
// const ProjectList = lazy(() => import('./ecommerce/ProjectList'));

// const Email = lazy(() => import('./apps/Email'));
// const Calendar = lazy(() => import('./apps/Calendar'));
// const Gallery = lazy(() => import('./apps/Gallery'));


const Activity = lazy(() => import('./pages/Activity'));
const Ehs = lazy(() => import('./pages/Ehs'));
const Gms = lazy(() => import('./pages/Gms'));
const Location = lazy(() => import('./pages/Location'));
const User = lazy(() => import('./pages/User'));
const AppUser = lazy(() => import('./pages/AppUser'));
const Eptw = lazy(() => import('./pages/Eptw'));
const Dropdown = lazy(() => import('./pages/Dropdown'));
const Incident = lazy(() => import('./pages/Incident'));
const Asset = lazy(() => import('./pages/Asset'));
// const RiskAssessment = lazy(() => import('./risk/RiskAssessment'));
const IncidentReview = lazy(() => import('./pages/IncidentReview'));
const Inspection = lazy(() => import('./pages/Inspection'));
const Plant = lazy(() => import('./pages/Plant'));
const Login = lazy(() => import('./user-pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
// const EditRisk = lazy(() => import('./risk/Routine'))
// const NonRoutine = lazy(() => import('./risk/NonRoutine'))
// const HazardBased = lazy(() => import('./risk/HazardBased'))
// const HazardList = lazy(() => import('./risk/HazardList'))
// const ViewRisk = lazy(() => import('./risk/ViewRisk'))
// const AmendRisk = lazy(() => import('./risk/AmendRisk'))
const PDFGenerate = lazy(() => import('./pages/PDFGenerate'))
const CostEstimator = lazy(() => import('./pages/CostEstimator'))
// const KnowledgeDashboard = lazy(() => import('./knowledge/Dashboard'))
// const KnowledgeCurate = lazy(() => import('./knowledge/Curate'))
// const KnowledgeAssignment = lazy(() => import('./knowledge/Assignment'))
// const KnowledgeStep = lazy(() => import('./knowledge/Steps'))
// const KaReports =lazy(()=>import('./knowledge/Reports'))
// const KnowledgeCuration = lazy(() => import('./curation/Curation'))
const Group = lazy(() => import('./group/Groups'))
// const Checklist = lazy(() => import('./checklist/Checklist'))
// const Document = lazy(() => import('./document/Document'))
// const ChecklistCurate = lazy(() => import('./curation/CFCuration'))
// const ChecklistAssignment = lazy(() => import('./checklist/Assignment'))
// const DocumentAssignment = lazy(() => import('./document/Assignment'))
// const HazardAmend = lazy(() => import('./risk/HazardAmend'))
// const HazardView = lazy(() => import('./risk/HazardView'))
// const ToolBoxTalk = lazy(() => import('./risk/ToolBoxTalk'))
const DownloadPDF = lazy(() => import('./pages/DownloadPDF'))
const Bank = lazy(() => import('./quesBank/QuestionList'))
const RISKDASH = lazy(() => import('./riskAssessment/DashBoard'))
// const KnowledgeDash =lazy(()=>import('./knowledge/Index'))
// const DocumentDash =lazy(()=>import('./document/Dashboard'))
// const ChecklistDash =lazy(()=>import('./checklist/Dashboard'))
// const DRAFT =lazy(()=> import('./risk/DraftRisk'))
// const HAZARDDRAFT =lazy(()=> import('./risk/HazardDraft'))
const ActionView = lazy(() => import('./pages/ActionView'))
const OBSDash = lazy(() => import('./observation/Dashboard'))
const EptwDash = lazy(() => import('./eptw-gen/Dashboard'))
const RoutineNew = lazy(() => import('./riskAssessment/Routine'))
const HazardNew = lazy(() => import('./riskAssessment/HazardBased'))
const InvestDash = lazy(() => import('./investigation/Dashboard'))
const RoutineEdit = lazy(() => import('./riskAssessment/EditRoutine'))
const OTT = lazy(() => import('./ott/Dashboard'))
const Test = lazy(() => import('./pages/Test'))
const AppRoutes = () => {
  const isLogin = useSelector((state) => state.login.isLogin);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Async function to validate token (if you fetch from server or have any async ops)
    const validateToken = async () => {
      // Simulating an API call
      return new Promise((resolve) => {
        setTimeout(() => {
          const hasValidToken = Boolean(localStorage.getItem('access_token'));
          resolve(hasValidToken);
        }, 1000); // Fake delay
      });
    };

    const checkLoginStatus = async () => {
      const hasValidToken = await validateToken();
      if (hasValidToken) {
        dispatch(loginActions.setLogin());
      } else {
        dispatch(loginActions.setLogout());
      }
      setIsLoading(false);
    };

    checkLoginStatus();
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }
  return (

    <Suspense fallback={<Spinner />}>
      <Switch>


        <Route exact path="/">
          {isLogin ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
        </Route>

        <Route path="/login">
          {isLogin ? <Redirect to="/dashboard" /> : <Login />}
        </Route>


        <Route path="/actions">
          {isLogin ? <Dashboard /> : <Redirect to="/login" />}
        </Route>

        <Route path="/dashboard">
          {isLogin ? <Home /> : <Redirect to="/login" />}
        </Route>
        <Route path="/actionview">
          {isLogin ? <ActionView /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/logout" >
          <Logout />
        </Route>

        <Route exact path="/ehs" >
          {isLogin ? <Ehs /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/apps/eptw-gen" >
          {isLogin ? <EptwDash /> : <Redirect to="/login" />}
        </Route>

        <Route path="/routinenew">
          {isLogin ? <RoutineNew /> : <Redirect to="/login" />}
        </Route>

        <Route path="/hazardnew">
          {isLogin ? <HazardNew /> : <Redirect to="/login" />}
        </Route>
        <Route path="/routineedit">
          {isLogin ? <RoutineEdit /> : <Redirect to="/login" />}
        </Route>
        <Route path="/apps/investigation">
          {isLogin ? <InvestDash /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/gms" >
          {isLogin ? <Gms /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/location" >
          {isLogin ? <Location /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/dropdown" >
          {isLogin ? <Dropdown /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/user" >
          {isLogin ? <User /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/app-user" >
          {isLogin ? <AppUser /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/activity" >
          {isLogin ? <Activity /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/eptw" >
          {isLogin ? <Eptw /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/ott" >
          {isLogin ? <OTT /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/incident" >
          {isLogin ? <Incident /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/apps/risk" >
          {isLogin ? <RISKDASH /> : <Redirect to="/login" />}
        </Route>
        {/* <Route exact path="/apps/knowledge" >
          {isLogin ? <KnowledgeDash /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/apps/document" >
          {isLogin ? <DocumentDash /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/apps/checklist" >
          {isLogin ? <ChecklistDash /> : <Redirect to="/login" />}
        </Route> */}

        <Route exact path="/apps/asset" >
          {isLogin ? <Asset /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/apps/observation" >
          {isLogin ? <OBSDash /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/test" >
          {isLogin ? <Test /> : <Redirect to="/login" />}
        </Route>

        {/* <Route exact path="/risk-assessment/dashboard" >
          {isLogin ? <RiskAssessment /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/risk-assessment/hazardbased" >
          {isLogin ? <HazardBased /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/risk-assessment/nonroutine" >
          {isLogin ? <NonRoutine /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/risk-assessment/draft" >
          {isLogin ? <DRAFT /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/risk-assessment/hazarddraft" >
          {isLogin ? <HAZARDDRAFT /> : <Redirect to="/login" />}
        </Route> */}

        <Route exact path="/cost-estimator" >
          {isLogin ? <CostEstimator /> : <Redirect to="/login" />}
        </Route>

        {/* 
        <Route exact path="/risk-assessment/routine" >
          {isLogin ? <EditRisk /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/risk-assessment/list" >
          {isLogin ? <HazardList /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/risk-assessment/viewrisk" >
          {isLogin ? <ViewRisk /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/risk-assessment/amendrisk" >
          {isLogin ? <AmendRisk /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/risk-assessment/amendhazard" >
          {isLogin ? <HazardAmend /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/risk-assessment/viewhazard" >
          {isLogin ? <HazardView /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/risk-assessment/toolbox" >
          {isLogin ? <ToolBoxTalk /> : <Redirect to="/login" />}
        </Route> */}


        <Route exact path="/inspection" >
          {isLogin ? <Inspection /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/plant" >
          {isLogin ? <Plant /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/bank" >
          {isLogin ? <Bank /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/incident-review" >
          {isLogin ? <IncidentReview /> : <Redirect to="/login" />}
        </Route>
        {/* <Route exact path="/pdf" >
          {isLogin ? <PDFGenerate /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/knowledge/dashboard" >
          {isLogin ? <KnowledgeDashboard /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/knowledge/curate" >
          {isLogin ? <KnowledgeCurate /> : <Redirect to="/login" />}
        </Route>
         */}
        {/* <Route exact path="/knowledge/report" >
          {isLogin ? <KaReports /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/knowledge/assignment" >
          {isLogin ? <KnowledgeAssignment /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/knowledge/curate/edit" >
          {isLogin ? <KnowledgeStep /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/knowledge/curate/add" >
          {isLogin ? <KnowledgeCuration /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/checklist/curate" >
          {isLogin ? <Checklist /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/document/curate" >
          {isLogin ? <Document /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/document/assignment" >
          {isLogin ? <DocumentAssignment /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/checklist/curate/edit" >
          {isLogin ? <ChecklistCurate /> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/document/curate/edit" >
          {isLogin ? <DocumentCurate/> : <Redirect to="/login" />}
        </Route> */}
        {/* <Route exact path="/checklist/assignment" >
          {isLogin ? <ChecklistAssignment /> : <Redirect to="/login" />}
        </Route> */}

        {/* <Route exact path="/group" >
          {isLogin ? <Group /> : <Redirect to="/login" />}
        </Route> */}

        <Route exact path="/login">
          {!isLogin ? <Login /> : <Redirect to="/dashboard" />}
        </Route>

        <Route path="/download-pdf/:id" component={DownloadPDF} />

      </Switch>
    </Suspense>
  );

}

export default AppRoutes;